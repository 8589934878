<template>
  <div id="referral-program">
    <Headline />
    <AccountReferralProgram />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import AccountReferralProgram from '../../components/account/AccountReferralProgram'

export default {
  components: {
    Headline,
    AccountReferralProgram
  }
}
</script>
